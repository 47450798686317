<template>
  <div id="domain-edit-multiple">
    <DomainEditMultipleHeadline />
    <DomainEditMultiple />
  </div>
</template>

<script>
import DomainEditMultipleHeadline from '@/components/domain/DomainEditMultipleHeadline.vue'
import DomainEditMultiple from '@/components/domain/DomainEditMultiple'

export default {
  components: {
    DomainEditMultipleHeadline,
    DomainEditMultiple
  }
}
</script>
