<template>
  <Headline :text="getHeading()" />
</template>

<script>
import Headline from '@/components/app/Headline.vue'

export default {
  components: {
    Headline
  },
  methods: {
    getHeading() {
      let selectedDomainsCount = this.$store.getters['domain/selectedDomainsCount']
      if (selectedDomainsCount) return this.$t('misc.edit') + ' ' + selectedDomainsCount + ' ' + this.$t('misc.domains')
    }
  }
}
</script>
